import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import './footer.css';
import appStore from '../utils/img/app.webp';
import PlayStore from '../utils/img/playstore.webp';
import LogoWithName from '../utils/img/logoLight.webp';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#202942', color: '#fff' }}>
      <Container className="py-4">
        <Row className="align-items-center">
          <Col lg={6} className="d-flex align-items-center">
            <Link to="#" className="d-inline-block">
              <img src={LogoWithName} height="50" alt="Logo" />
            </Link>
          </Col>
          <Col lg={6} className="d-flex justify-content-end align-items-center">
            <div className="text-md-end">
              <h4 className="fw-bold text-light title-dark m-2">
                Download our app now!
              </h4>
              <a
                href="https://apps.apple.com/in/app/liltriangle/id956406796"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appStore} className="m-1" height="50" alt="Download on App Store" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.junior&hl=en_US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={PlayStore} className="m-1" height="50" alt="Get it on Google Play" />
              </a>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg='5'>
            <p className='m-2 ms-3'>
              <b className='m-0'>Contact us:</b><br />
              lilTriangle.<br />
              4th E Cross Rd,MVS Arcade, 659,B.Channasandra Main Rd<br />
              OMBR Layout, Bengaluru 560043<br />
              Karnataka (India)<br />
              Business Email:sales@lilTriangle.com<br />
              Phone No: +91 7676 5488 53<br />
              Support Email: hello@lilTriangle.com<br />
            </p>
          </Col>
          <Col lg='7'>
            <div className='text-end'>
              <p className='m-2 mb-3'>Follow Us</p>
              <ul className="social-media-icons list-unstyled d-flex justify-content-end">
                <li><a href="https://www.facebook.com/liltriangleapp/" target='_blank' rel="noopener noreferrer" className="facebook"><i className="fab fa-facebook-f icon"></i></a></li>
                <li><a href="https://twitter.com/triangle_lil" target='_blank' rel="noopener noreferrer" className="twitter"><i class="fa-brands fa-x-twitter"></i></a></li>
                
                <li><a href="https://www.linkedin.com/company/lil-triangle/" target='_blank' rel="noopener noreferrer" className="linkedin"><i className="fab fa-linkedin-in icon"></i></a></li>
                <li><a href="https://www.instagram.com/liltriangleapp/" target='_blank' rel="noopener noreferrer" className="instagram"><i className="fab fa-instagram icon"></i></a></li>
              </ul>
            </div>
          </Col>
        </Row>

        <hr style={{ margin: 0, width: '100%' }} />
        <Row className="footer-bar text-center align-items-center">
          <Col sm={6}>
            <div className="text-sm-start">
              <p className="mb-0 mt-2">
                © {(new Date().getFullYear())} lilTriangle. Design with ❤️ {" "}
                <i className="mdi mdi-heart text-danger"></i> by {" "}
                <Link
                  to="https://liltriangle.com/"
                  target="_blank"
                  className="text-reset"
                  rel="noopener noreferrer"
                >
                  lilTriangle
                </Link>.
              </p>
            </div>
          </Col>
          <Col sm={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
            <ul className="list-unstyled text-sm-end mb-0 d-flex gap-1 flex-wrap justify-content-sm-end">
              <p className="mb-0">lilTriangle, a Product of Slate and Chalk Innovations Pvt Ltd</p>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
