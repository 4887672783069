import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, FormGroup, Label, Input, Button, Col, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import ProfileImage from '../utils/img/ProfileImage.png';
import StudentProfileHeader from './StudentProfileHeader';

const StudentProfileDetails = (props) => {
    const location = useLocation();

    const match = matchPath({
        path: "/StudentProfileDetails/:schoolKey",
        exact: true,
        strict: false,
    }, location.pathname);

    const schoolKey = match ? match.params.schoolKey : null;

    const [data, setData] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchSchool = async () => {
        setLoading(true);
        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/getLandingPage", {
                method: "POST",
                body: JSON.stringify({ key: schoolKey }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });

            if (response.ok) {
                const result = await response.json();
                setData(result.result);
                setLoading(false);
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchSchool();
    }, [schoolKey]);

    return (
        <div style={{ backgroundColor: '#FBFCFD', minHeight: '100vh' }}>
            {loading ? (
                <div style={{ marginTop: '2cm' }}>
                </div>
            ) : (
                <Container style={{ marginTop: '2cm', }}>
                    <div>
                        <center>
                            <StudentProfileHeader data={data} />
                        </center>
                        <center>
                            <Row>
                                <Col lg={4}>
                                    <Card style={{ width: '200px', height: '220px' }} className='text-center'>
                                        <CardBody>
                                            <img src={ProfileImage} alt="ProfileImage" style={{ width: '150px', height: '200px' }}></img>
                                            <p className='m-2'>P.Shaziya</p>
                                            <p>UKG</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={5}>
                                    <b style={{ textDecoration: 'underline', display: 'flex', justifyContent: 'start' }} className='m-1'>All Invoices:</b>
                                    <Card style={{ width: '600px', height: '100px' }} className='text-center'>
                                        <CardBody style={{ position: 'relative', height: '100%' }}>
                                            <div style={{ display: 'flex', justifyContent: 'start' }}>
                                                <p className='m-0'>1. LKG FEES</p>
                                                
                                            </div>
                                            <div style={{ position: 'absolute', bottom: '10px', right: '10px', display: 'flex', gap: '10px' }}>
                                                <Button color="success">Pay Now</Button>
                                                <Button color="secondary">Download Receipt</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>


                            </Row>
                        </center>
                    </div>
                </Container>
            )}
        </div>
    );
};

export default StudentProfileDetails;
