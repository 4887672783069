import React, { useState, useEffect } from 'react';
import { useLocation, matchPath, Link } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './fadeIn.css';
import '../App.js';
import SchoolImage from '../utils/img/schoolImage.png';
import { Skeleton, Space } from 'antd';
import { FloatButton } from 'antd';

const SchoolDetails = () => {
  const location = useLocation();

  const match = matchPath(
    {
      path: "/:schoolKey",
      exact: true,
      strict: false,
    },
    location.pathname
  );

  const schoolKey = match ? match.params.schoolKey : null;

  const [data, setData] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSchool();
  }, [schoolKey]);

  const fetchSchool = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://liltriangle.b4a.app/examVerification/getLandingPage",
        {
          method: "POST",
          body: JSON.stringify({ key: schoolKey }),
          headers: { "Content-type": "application/json; charset=UTF-8" },
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setData(result.result);
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEnquiryFormClick = () => {
    if (data.id) {
      window.location.href = `https://forms.liltriangle.com/enquiry-form/${data.id}`;
    }
  };

  return (
    <div className="school-details" style={{ backgroundColor: '#ffffff', padding: '0.5cm' }}>
      {loading ? (
        <div style={{ marginTop: '2cm' }}>
          {/* <Space> */}

          <Row>
            <Skeleton.Image active={loading} className='m-2' />
            {/* <Skeleton.Image active={loading}  size={'default'} shape={'default'}/> */}
            <Col >
              <Skeleton.Button active={loading} size={'default'} shape={'default'} block={true} />
              <Skeleton.Input active={loading} size={'small'}  style={{marginBottom: '1cm'}}/>
            </Col>
            <Col>
              {/* <Skeleton.Image active={loading} size={'default'} shape={'default'} /> */}
            </Col>
            <Skeleton
              active={loading}
              paragraph={{
                rows: 20,
              }}

            />
            {/* <Skeleton.Image active={loading} size={'large'} shape={'large'} /> */}


          </Row>







          {/* </Space> */}
        </div>
      ) : (
        <div>
          <Row style={{ marginBottom: '1cm' }}>
            <Col lg="6" className="text-section">
              <div className="d-flex align-items-center" style={{ marginTop: '2cm' }}>
                {data.schoolLogo && data.name && (
                  <div className="d-flex align-items-center">
                    <img
                      src={data.schoolLogo}
                      className="mt-0"
                      style={{ height: '10vh' }}
                      alt="SchoolLogo"
                    />
                    <strong style={{ color: '#ff0075', fontSize: '30px', marginLeft: '10px' }}>
                      {data.name}
                    </strong>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={6} className="d-flex align-items-end">
              {data.id ? (
                <Button
                  onClick={handleEnquiryFormClick}
                  style={{
                    minWidth: '2.6cm', 
                    height: '40px', 
                    padding: '0 12px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    alignItems: 'center',
                    border: '1px solid #d9d9d9',
                    backgroundColor: '#02CCFE',
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    zIndex: 1000,
                  }}
                >
                  Enquire Now
                </Button>
              ) : null}
            </Col>

          </Row>

          <Row>
            <Col lg="6">
              <div className="m-0">
                {data.address && (
                  <div className="d-flex align-items-center">
                    <i className="fa fa-map-marker me-2">:</i>
                    <span>{data.address}</span>
                  </div>
                )}
              </div>

              <div className="m-0">
                {data.object?.phoneNumber && (
                  <div className="d-flex align-items-center">
                    <i className="fa fa-phone me-1"></i>
                    <p className="mb-0 me-1">:</p>
                    <a href={`tel:${data.object.phoneNumber}`} className="text-decoration-none" style={{ color: '#187bcd' }}>
                      {data.object.phoneNumber}
                    </a>
                  </div>
                )}
              </div>

              <div className="m-0">
                {data.email && (
                  <div className="d-flex align-items-center">
                    <i className="fa fa-envelope me-2"></i>
                    <p className="mb-0 me-1">:</p>
                    <a href={`mailto:${data.email}`} style={{ color: '#187bcd' }}>
                      {data.email}
                    </a>
                  </div>
                )}
              </div>

              <div className="m-0">
                {data.object?.schoolWebsite && (
                  <div className="d-flex align-items-center">
                    <i className="fa fa-globe me-2">:</i>
                    <a
                      href={data.object.schoolWebsite}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#187bcd' }}
                    >
                      {data.object.schoolWebsite}
                    </a>
                  </div>
                )}
              </div>

              <div className="mt-0">
                {data.aboutSchool && (
                  <div className="d-flex align-items-start">
                    <i className="fas fa-school me-2" style={{ fontSize: '1.2em' }}></i>
                    <div>
                      <b className="me-1" style={{ textDecoration: 'underline' }}>About School:</b>
                      <p className="mb-0">{data.aboutSchool}</p>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col lg="6" className="d-flex justify-content-end">
              {data.object.schoolPoster ? (
                <img src={data.object.schoolPoster.url} alt="school poster" style={{ height: '10cm', width: '15cm' }} />
              ) : (
                <img src={SchoolImage} alt="school image" style={{ height: '10cm', width: '15cm' }}/>
              )}
            </Col>

          </Row>

          <div className='m-0'>
            {data.terms && (
              <div className="text-start m-1 ms-1">
                <Link to={`/terms-of-use/${data.schoolKey}`} style={{ textDecoration: 'underline', color: 'blue', marginRight: '8px' }}>
                  <i className="fa fa-info-circle me-2" style={{ color: 'black' }}> :</i>
                  Terms of Use
                </Link>
              </div>
            )}

            {(data.facebook || data.instagram || data.twitter || data.object?.youtube) && (
              <div>
                <b className='m-3 ms-3' style={{ textDecoration: 'underline' }}>School Media Links</b>
                <div className="text-start mt-2">
                  {data.facebook && (
                    <div>
                      <i className="fa-brands fa-facebook" style={{ marginRight: '8px' }}> :</i>
                      <a
                        href={data.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#187bcd' }}
                      >
                        {data.facebook}
                      </a>
                    </div>
                  )}
                  {data.instagram && (
                    <div>
                      <i className="fa-brands fa-instagram" style={{ marginRight: '8px' }}> :</i>
                      <a
                        href={data.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#187bcd' }}
                      >
                        {data.instagram}
                      </a>
                    </div>
                  )}
                  {data.twitter && (
                    <div>
                      <i className="fa-brands fa-twitter" style={{ marginRight: '8px' }}> :</i>
                      <a
                        href={data.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#187bcd' }}
                      >
                        {data.twitter}
                      </a>
                    </div>
                  )}
                  {data.object?.youtube && (
                    <div>
                      <i className="fa-brands fa-youtube" style={{ marginRight: '8px' }}> :</i>
                      <a
                        href={data.object.youtube}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#187bcd' }}
                      >
                        {data.object.youtube}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <hr />

          <Row className="text-center justify-content-center mb-4">
            {data.services && data.services.length > 0 && (
              <Col md="4" sm="12" xs="12">
                <div className="mb-4" style={{ backgroundColor: '#ff0075' }}>
                  <h5 className="text-white" style={{ textDecoration: 'underline' }}>
                    Services Offered
                  </h5>
                  <ul className="list-unstyled">
                    {data.services.map((service, index) => (
                      <li key={index} className="text-white">
                        {service} <i className="fa fa-check" style={{ color: '#fff' }}></i>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            )}

            {data.facilities && data.facilities.length > 0 && (
              <Col md="4" sm="12" xs="12">
                <div className="mb-4" style={{ backgroundColor: '#ff0075' }}>
                  <h5 className="text-white" style={{ textDecoration: 'underline' }}>
                    Facilities Offered
                  </h5>
                  <ul className="list-unstyled">
                    {data.facilities.map((facility, index) => (
                      <li key={index} className="text-white">
                        {facility} <i className="fa fa-check" style={{ color: '#fff' }}></i>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            )}

            {data.staff && data.staff.length > 0 && (
              <Col md="4" sm="12" xs="12">
                <div className="mb-4" style={{ backgroundColor: '#ff0075' }}>
                  <h5 className="text-white" style={{ textDecoration: 'underline' }}>
                    About Staff
                  </h5>
                  <ul className="list-unstyled">
                    {data.staff.map((staffMember, index) => (
                      <li key={index} className="text-white">
                        {staffMember} <i className="fa fa-check" style={{ color: '#fff' }}></i>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}
    </div>
  );
}

export default SchoolDetails;
