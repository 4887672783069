import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Col, Row } from 'reactstrap';
import { useLocation, Link } from 'react-router-dom';
import { matchPath } from 'react-router';
import ProfileImage from '../utils/img/ProfileImage.png';
import StudentProfileHeader from './StudentProfileHeader';

const StudentProfile = () => {
    const location = useLocation();

    const match = matchPath({
        path: "/StudentProfile/:schoolKey",
        exact: true,
        strict: false,
    }, location.pathname);

    const schoolKey = match ? match.params.schoolKey : null;

    const [data, setData] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchSchool = async () => {
        setLoading(true);
        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/getLandingPage", {
                method: "POST",
                body: JSON.stringify({ key: schoolKey }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });

            if (response.ok) {
                const result = await response.json();
                setData(result.result);
                setLoading(false);
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchSchool();
    }, [schoolKey]);

    return (
        <div style={{ backgroundColor: '#FBFCFD', minHeight: '100vh' }}>
            {loading ? (
                <div style={{ marginTop: '2cm' }}>
                </div>
            ) : (
                <Container style={{ marginTop: '2cm' }}>
                    <center>
                        <StudentProfileHeader data={data} />
                    </center>
                    <div>
                        <center>
                            <Row>
                                <Col lg={6}>
                                    {data.schoolKey && (
                                        <Link to={`/StudentProfileDetails/${data.schoolKey}`}
                                            style={{ textDecoration: 'none' }}>
                                            <Card
                                                style={{ width: '200px', height: '220px', cursor: 'pointer' }}
                                                className='text-center'
                                            >
                                                <CardBody>
                                                    <img
                                                        src={ProfileImage}
                                                        alt="ProfileImage"
                                                        style={{ width: '150px', height: '200px' }}
                                                    />
                                                    <p className='m-2'>P.Shaziya</p>
                                                    {/* attributes.name */}
                                                    <p>UKG</p>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    )}
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ width: '200px', height: '220px' }} className='text-center'>
                                        <CardBody>
                                            <img src={ProfileImage} alt="ProfileImage" style={{ width: '150px', height: '200px' }}></img>
                                            <p className='m-2'>P.Shaziya</p>
                                            <p>UKG</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </center>
                    </div>
                </Container>
            )}
        </div>
    );
};

export default StudentProfile;
