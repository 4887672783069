import React from 'react';
import { MenuBtn } from '../components/MenuBtn';
import './Home.css';
import AboutImg from '../utils/img/background.webp';
import { Link } from 'react-router-dom';
import { ContactInfo } from '../components/ContactInfo';
import ContactImage from '../utils/img/contact-img.jpg';
import ImageGallery from '../components/ImageGallery';


function Home() {
    return (
        <div className='home-page'>
            <header className='h-100 min-vh-100 d-flex align-items-center text-light shadow'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6 d-flex d-sm-block flex-column align-items-center'>
                            
                            <h1 className='mb-0 text-black fw-bold'>lilTriangle</h1>
                            <h3 className='mb-5 text-black fw-bold text-center text-sm-start'>Simplify & Empower Your Preschool & Daycare Operations</h3>

                        </div>
                    </div>
                </div>
            </header>

            

            

            <ImageGallery />

            
        </div>
    )
}

export default Home;