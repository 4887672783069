import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, FormGroup, Label, Input, Button, Col, Row } from 'reactstrap';
import { useLocation, Link } from 'react-router-dom';
import { matchPath } from 'react-router';
import LoginImage from '../utils/img/StudentLogin.png';
import StudentProfileHeader from './StudentProfileHeader';

const Login = (props) => {
    const location = useLocation();

    const match = matchPath({
        path: "/StudentLogin/:schoolKey",
        exact: true,
        strict: false,
    }, location.pathname);

    const schoolKey = match ? match.params.schoolKey : null;

    const [data, setData] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [verificationState, setVerificationState] = useState(null);
    const [userId, setUserId] = useState('');
    const [otp, setOtp] = useState('');

    const validatePhoneNumber = (phoneNumber) => {
        const phonePattern = /^([0-9]{10,16})$/;
        if (!phoneNumber) {
            return "Phone number is required";
        } else if (!phonePattern.test(phoneNumber)) {
            return "You have entered an invalid phone number!";
        }
        return "";
    };

    const handleVerifyNumber = async () => {
        const phoneNumberError = validatePhoneNumber(phoneNumber);
        if (phoneNumberError) {
            setPhoneNumberError(phoneNumberError);
            return;
        }
    
        setLoading(true);
        setPhoneNumberError('');
        setVerificationState('otp');
    
        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/verifyuserName", {
                method: "POST",
                body: JSON.stringify({ userId: userId }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });
    
            if (response.ok) {
                const result = await response.json();
                setData(result.result);
                console.log(result);
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };
    

    const handleOtpSubmit = async () => {

    };

    const fetchSchool = async () => {
        setLoading(true);
        try {
            const response = await fetch("https://liltriangle.b4a.app/examVerification/getLandingPage", {
                method: "POST",
                body: JSON.stringify({ key: schoolKey }),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            });

            if (response.ok) {
                const result = await response.json();
                setData(result.result);
                setLoading(false);
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchSchool();
    }, [schoolKey]);

    return (
        <React.Fragment>
        <div style={{ backgroundColor: '#FBFCFD', minHeight: '90vh' }}>
            {loading ? (
                <div style={{ marginTop: '2cm' }}>
                </div>
            ) : (
                <Container style={{ marginTop: '20px', textAlign: 'center' }}>
                    
                    <center>
                        <StudentProfileHeader data={data} />
                    </center>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2.1cm' }}>
                        <Row>
                            <Card className='m-2 shadow p-2' style={{ display: 'flex', justifyContent: 'end', width: '500px' }}>
                                <CardBody>
                                    <img src={LoginImage} alt="Login Image" style={{height: '2cm',width:'80%'}}></img>
                                    {verificationState === null ? (
                                        <FormGroup>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                                                <Label className='m-1' style={{ color: '#000000' }}>Phone Number <span className="text-danger">*</span></Label>
                                                <Input type="text" placeholder="Enter Phone Number" value={phoneNumber} onChange={(e) => {
                                                    setPhoneNumber(e.target.value);
                                                    setPhoneNumberError(validatePhoneNumber(e.target.value));
                                                }}
                                                />
                                                {phoneNumberError && <div className="text-danger">{phoneNumberError}</div>}
                                            </div>
                                        </FormGroup>
                                    ) : verificationState === 'otp' ? (
                                        <>
                                            <FormGroup>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    <Label className='m-1'>Phone Number</Label>
                                                    <Input type="text" value={phoneNumber} readOnly />
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    <Label className='m-1'>Enter OTP :<span className="text-danger">*</span></Label>
                                                    <Input
                                                        type="text"
                                                        value={otp}
                                                        onChange={(e) => setOtp(e.target.value)}
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                            </FormGroup>
                                            <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'end' }}>
                                                <p className="text-muted">Didn't receive the OTP?</p>
                                                <a href="#" style={{ textDecoration: 'underline', color: 'blue', marginLeft: '5px' }}>Resend OTP</a>
                                            </div>
                                        </>
                                    ) : null}
                                    {verificationState === 'otp' ? (
                                        <Link to={`/StudentProfile/${data.schoolKey}`}>
                                            <Button
                                                style={{
                                                    background: '#fe1386',
                                                    borderRadius: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                                className='m-3'
                                                onClick={handleOtpSubmit}
                                            >
                                                Submit
                                                <i className="fa-solid fa-chevron-right"
                                                    style={{
                                                        color: '#fe1386',
                                                        backgroundColor: 'white',
                                                        borderRadius: '50%',
                                                        padding: '5px',
                                                        marginLeft: '10px'
                                                    }}
                                                ></i>
                                            </Button>
                                        </Link>
                                    ) : (
                                        <Button
                                            style={{
                                                background: '#fe1386',
                                                borderRadius: '20px',
                                                fontWeight: 'bold',
                                            }}
                                            className='m-3'
                                            onClick={handleVerifyNumber}
                                        >
                                            Verify
                                            <i className="fa-solid fa-chevron-right"
                                                style={{
                                                    color: '#fe1386',
                                                    backgroundColor: 'white',
                                                    borderRadius: '50%',
                                                    padding: '5px',
                                                    marginLeft: '10px'
                                                }}
                                            ></i>
                                        </Button>
                                    )}
                                </CardBody>
                            </Card>
                        </Row>
                    </div>
                </Container>
            )}
        </div>
        </React.Fragment>
    );
};

export default Login;
