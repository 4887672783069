import React from 'react';
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Home from './pages/Home';
import Menu from './pages/Menu';
import About from './pages/About';
import Contact from './pages/Contact';
import ImageGallery from './components/ImageGallery';
import SchoolDetails from './components/SchoolDetails';
import TermsOfUse from './components/TermsOfUse';
import Login from './components/Login';
import StudentProfile from './components/StudentProfile';
import StudentProfileDetails from './components/StudentProfileDetails'
import Footer from './components/footer';
import StudentProfileHeader from './components/StudentProfileHeader';
import lilLogo from './utils/img/lilLogo.webp'

function App() {
  const location = useLocation();
  const shouldHideNavAndFooter = location.pathname.includes('/terms-of-use') || location.pathname.includes('/StudentLogin')  ||  location.pathname.includes('/StudentProfile') || location.pathname.includes('/StudentProfileDetails') ;
  

  return (
    <div>
      {!shouldHideNavAndFooter && (
        <Navbar expand="lg" className='fixed-top bg-body-tertiary shadow'>
          <Container>



            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex align-items-center">
                <img src={lilLogo} alt='lilLogo' style={{ height: '7vh' }} className='me-2'></img>

                <Navbar.Brand>
                  <p className='navbar-brand text-pink fw-semibold'>
                    lilTriangle
                  </p>
                </Navbar.Brand>
              </div>

              <h5 className='mb-0'>Dashboard for Preschools</h5>
            </div>





            {/* <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='me-auto justify-content-end w-100'>
                <Link to="/" className='nav-link active text-uppercase' style={{color:'red'}}>Home</Link>
                <Link to="/menu" className='nav-link text-uppercase' style={{color:'blue'}}>Reviews</Link>
                <Link to="/about" className='nav-link text-uppercase' style={{color:'yellow'}}>About</Link>
                <Link to="/contact" className='nav-link text-uppercase' style={{color:'purple'}}>Contact</Link>
              </Nav>
            </Navbar.Collapse> */}
          </Container>
        </Navbar>
      )}

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/menu' element={<Menu />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/gallery' element={<ImageGallery />} />
        <Route path='/:schoolKey' element={<SchoolDetails />} />
        <Route path='/terms-of-use/:schoolKey' element={<TermsOfUse />} />
        <Route path='/:schoolKey' element={<SchoolDetails />} />
        <Route path='/StudentLogin/:schoolKey' element={<Login />} />
        <Route path='/StudentProfile/:schoolKey' element= {<StudentProfile />} />
        <Route path='/StudentProfileDetails/:schoolKey' element= {<StudentProfileDetails />} />

      </Routes>

      {!shouldHideNavAndFooter && <Footer />}
    </div>
  );
}

export default App;
